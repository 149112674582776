import React, { useEffect, useRef, useState, useCallback } from "react";
import { gsap } from "gsap/dist/gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import globe from "../../../assets/img/globe.png";
import gallery1 from "../../../assets/img/img-1.png";
import gallery2 from "../../../assets/img/img-2.png";
import gallery3 from "../../../assets/img/img-3.png";
import gallery4 from "../../../assets/img/img-4.png";
import gallery5 from "../../../assets/img/img-5.png";
import gallery6 from "../../../assets/img/img-6.png";
import gallery7 from "../../../assets/img/img-7.jpg";
import gallery8 from "../../../assets/img/img-8.jpg";
import gallery9 from "../../../assets/img/img-9.jpg";
import gallery10 from "../../../assets/img/img-10.jpg";
import gallery11 from "../../../assets/img/img-11.jpg";
import placeIm7ge from "../../../assets/img/placeholder.png";
import profile from "../../../assets/img/profile.png";
import download from "../../../assets/svg/download.svg";
import video from "../../../assets/svg/video.svg";
import close from "../../../assets/svg/close.svg";
import up from "../../../assets/svg/up.svg";
import playVideo from "../../../assets/others/play.mp4";
import pdf from "../../../assets/pdf/blockchainfirm.pdf"
import "./home.scss";
import { Link } from "react-router-dom";


const Home = () => {

  const pdffile = "../../../assets/pdf/blockchainfirm.pdf"

  gsap.registerPlugin(ScrollTrigger);
  const canvasRef = useRef(null);
  const designRef = useRef(null);
  const imgRef = useRef(null);
  const workRef = useRef(null);
  const panel2 = useRef(null);
  const panel3 = useRef(null);
  const projectRef = useRef(null);
  const overlaySlide = useRef(null);
  const projectTitle = useRef(null)
  const [count, setCount] = useState(0);

  window.onload = localStorage.clear()
  const initAnimation = useCallback((context, images, branding) => {
    // function initAnimation(){
    setCount(count + 1);
    let userAgent = navigator.userAgent;
    let browserName;

    if (userAgent.match(/chrome|chromium|crios/i)) {
      browserName = "chrome";

    } else if (userAgent.match(/firefox|fxios/i)) {
      browserName = "firefox";
    } else if (userAgent.match(/safari/i)) {
      browserName = "safari";
    } else if (userAgent.match(/opr\//i)) {
      browserName = "opera";
    } else if (userAgent.match(/edg/i)) {
      browserName = "edge";
    } else {
      browserName = "No browser detection";
    }
    let conditionCheck = browserName !== "chrome" ? (+localStorage.getItem('count') > 0 || localStorage.getItem('count') == null) : localStorage.getItem('count') == null;
    if (conditionCheck) {
      for (let i = 0; i <= 46; i++) {
        if (i === 46) {
        }
        setTimeout(() => {
          context.clearRect(
            0,
            0,
            canvasRef.current.width,
            canvasRef.current.height
          );
          context.drawImage(images[i], 0, 0);
          animateImageSequence(i)
        }, browserName === 'chrome' ? (i * 50) : (i * 50));

      };
    }
    localStorage.setItem('count', 1);


    // }

    function animateImageSequence() {
      gsap.timeline().fromTo(branding, {
        snap: 'frame',
        frame: branding.frame,
        ease: "none",
        duration: 0.1,
        opacity: 0,
        onComplete: () => {
          // return branding.frame
        }
      }, {
        opacity: 1
      },
        0)

    }
  }, []);


  const browserCheck = () => {

  }
  // window.onload = localStorage?.setItem('count' , 0)
  useEffect(() => {

    // +localStorage.getItem('count') >= 0 ? localStorage.setItem('count' , +localStorage.getItem('count') + 1) : localStorage.setItem('count', 0)
    const designElement = designRef.current;
    let text = designElement.querySelector(".text");
    let text1 = designElement.querySelector(".text1");
    let text2 = designElement.querySelector(".text2");
    let text3 = designElement.querySelector(".text3");
    let text4 = designElement.querySelector(".text4");
    let text5 = designElement.querySelector(".text5");
    let text6 = designElement.querySelector(".text6");
    let text7 = designElement.querySelector(".text7");
    const context = canvasRef.current.getContext("2d");
    canvasRef.current.width = 1920;
    canvasRef.current.height = 1080;
    const frameCount = 487;
    const currentFrame = (index) =>
      `https://d2e0e4h4nd8arg.cloudfront.net/brand/${(index + 0)
        .toString()
        .padStart(4, "0")}.jpg`;
    const images = [];
    const branding = {
      frame: 46,
    };
    const firstframe = {
      frameset: 0
    }
    for (let i = 0; i < frameCount; i++) {
      const img = new Image();
      img.src = currentFrame(i);
      images.push(img);
    }

    // function  initialRender(){
    //     // context.clearRect(
    //     //   0,
    //     //   0,
    //     //   canvasRef.current.width,
    //     //   canvasRef.current.height
    //     // );
    //     // context.drawImage(images[branding.frame], 0, 0);
    //     console.log(branding.frame)
    //   }

    initAnimation(context, images, branding);


    gsap
      .timeline({
        // onStart:onenterEvent,
        onUpdate: render,
        scrollTrigger: {
          trigger: ".design",
          start: "top top",
          end: "+=8000",
          scrub: 1,
          pin: true,
          markers: false,
          pinSpacing: false,
        },

      })
      .add("start0")

      /* Main Text Animation */
      .to(text, { duration: 5, y: -20, opacity: 1, }, "start0")
      .to(text, { opacity: 0, autoAlpha: 0 })


      .add("start1")

      /* Main Text Animation */
      .to(text1, { delay: 1, duration: 15, opacity: 1, y: -50, autoAlpha: 1 }, "start1")
      .to(text1, { duration: 3.5, opacity: 0, y: -100, autoAlpha: 0 })

      .add("start2")

      /* Main Text Animation */
      .to(text2, { delay: 1, duration: 18, opacity: 1, y: -50, autoAlpha: 1 }, "start2")
      .to(text2, { duration: 2, opacity: 0, y: -100, autoAlpha: 0 })

      .add("start3")

      /* Main Text Animation */
      .to(text3, { delay: 1, duration: 25, opacity: 1, y: -50, autoAlpha: 1 }, "start3")
      .to(text3, { duration: 3.5, opacity: 0, y: -100, autoAlpha: 0 })

      .add("start4")

      /* Main Text Animation */
      .to(text4, { delay: 1, duration: 20, opacity: 1, y: -50, autoAlpha: 1 }, "start4")
      .to(text4, { duration: 3.5, opacity: 0, y: -100, autoAlpha: 0 })

      .add("start5")

      /* Main Text Animation */
      .to(text5, { delay: 1, duration: 20, opacity: 1, y: -50, autoAlpha: 1 }, "start5")
      .to(text5, { duration: 3.5, opacity: 0, y: -100, autoAlpha: 0 })

      .add("start6")

      /* Main Text Animation */
      .to(text6, { delay: 1, duration: 10, opacity: 1, y: -50, autoAlpha: 1 }, "start6")
      .to(text6, { duration: 3.5, opacity: 0, y: -100, autoAlpha: 0 })

      .add("start7")

      /* Main Text Animation */
      .to(text7, { delay: 1, duration: 30, opacity: 1, y: -50, autoAlpha: 1 }, "start7")
      .to(text7, { duration: 3.5, opacity: 1, y: -100, autoAlpha: 0 })
      .to(
        branding,
        {
          frame: frameCount - 1,
          snap: "frame",
          ease: "none",
          duration: 150,
        },
        0
      )
      // .from(firstframe,{
      //   frame: frameCount - 1,
      //   ease: "none",
      //   duration: 150,
      // },
      // 0)
      ;

    // gsap.fromTo(text,{opacity:1,yPercent:60},
    //   {yPercent:0,opacity:1,duration:1 ,delay:1})

    function render() {
      context.clearRect(
        0,
        0,
        canvasRef.current.width,
        canvasRef.current.height
      );
      context.drawImage(images[branding.frame], 0, 0);
    }

  }, [initAnimation]);



  useEffect(() => {
    const el = imgRef.current;
    gsap.fromTo(
      el,
      { scale: 0.9 },
      {
        scale: 1,
        duration: 1,
        scrollTrigger: {
          trigger: el,
          start: "+=8700",

          toggleActions: "play none none reverse",
          markers: false,
        },
      }
    );
  }, []);


  useEffect(() => {
    const el = workRef.current;
    gsap.fromTo(
      el,
      { opacity: 0, y: 50 },
      {
        opacity: 2,
        y: 0,

        duration: 0.5,
        scrollTrigger: {
          trigger: el,
          start: "+=8500",
          toggleActions: "play none none reverse",
          markers: false,
        },
      }
    );
  }, []);
  useEffect(() => {
    const el = workRef.current;
    gsap.fromTo(
      el,
      { opacity: 2, y: 0, duration: 1 },
      {
        opacity: 0,
        y: -50,

        duration: 0.5,
        scrollTrigger: {
          trigger: el,
          start: "+=10650",
          toggleActions: "play none none reverse",
          markers: false,
        },
      }
    );
  }, []);
  useEffect(() => {
    const el = workRef.current;
    gsap.set(
      el,
      { opacity: 0 },

    );
  }, []);

  useEffect(() => {
    const el = projectTitle.current;
    gsap.fromTo(
      el,
      { y: 50, opacity: 0 },

      {
        y: 0,
        duration: 0.5,

        opacity: 1,
        scrollTrigger: {
          trigger: el,
          start: "+=7950",
          toggleActions: "play none none reverse",
          markers: false,
        },
      }
    );
  }, []);
  useEffect(() => {
    const el = projectTitle.current;
    gsap.fromTo(
      el,
      { opacity: 1, y: 0, autoAlpha: 1 },

      {
        y: -50,


        opacity: 0,
        autoAlpha: 0,
        scrollTrigger: {
          trigger: el,
          start: "+=8550",
          toggleActions: "play none none reverse",
          markers: false,
        },
      }
    );
  }, []);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const tl = gsap.timeline({
      scrollTrigger: {
        start: "+=8400",
        scrub: 0.1,
        toggleActions: "play none none reverse",
      },
    });
    tl.from(projectRef.current, { opacity: 1 })
      .to(projectRef.current, { opacity: 0, })
      .to(panel2.current, { opacity: 1 })
      .from(panel2.current, { opacity: 1 })
  }, []);

  useEffect(() => {
    const el = overlaySlide.current;
    gsap.to(
      el,
      { x: 0 },

    );
  }, []);

  useEffect(() => {
    const el = panel2.current;
    gsap.fromTo(
      el,
      { zIndex: 8 },
      {
        zIndex: 9,
        scrollTrigger: {
          trigger: el,
          duration: 1,
          start: "+=8800",
          toggleActions: "play none none reverse",
          markers: false,
        },
      }
    );
  }, []);


  useEffect(() => {
    const el = overlaySlide.current;
    gsap.fromTo(
      el,
      { x: 0 },
      {
        x: -950,
        ease: "Power3.easeOut",
        duration: 1,
        scrollTrigger: {
          trigger: el,
          duration: 1,
          start: "+=8800",
          toggleActions: "play none none reverse",
          markers: false,
        },
      }
    );
  }, []);


  useEffect(() => {
    const el = overlaySlide.current;
    gsap.fromTo(
      el,
      {
        x: -950,
        ease: "Power4.easeOut",

      },
      {
        x: 0,
        duration: 0.8,
        scrollTrigger: {
          trigger: el,
          // duration: 120,
          start: "+=10800",
          toggleActions: "play none none reverse",
          markers: false,
        },
      }
    );
  }, []);

  const containerRef = useRef(null);
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    const container = containerRef.current;
    const scrollContainer = scrollContainerRef.current;

    const X = 8500 - container.offsetWidth;

    let tl = gsap.timeline({
      ease: "slow",
      scrollTrigger: {
        trigger: scrollContainer,
        scrub: true,
        duration: 80,
        start: "top ",
        end: "+=6000",
        pin: true,
        markers: false,
      },
    });

    gsap.utils.toArray(".module").forEach((el, i) => {
      tl.fromTo(
        el,
        { x: 5000 },
        { x: `-=${X}`, autoAlpha: 1, duration: 0.1 },
        "-=0.1"
      );
    });
  }, []);

  useEffect(() => {
    const el = panel3.current;
    gsap.fromTo(
      el,
      { opacity: 0, },
      {
        opacity: 1,
        duration: 1,
        // autoAlpha: 1,
        scrollTrigger: {
          trigger: el,
          start: "top -150%",

          toggleActions: "play none none reverse",
          markers: false,
        },
      }
    );
  }, []);

  const profileRef = useRef(null);


  useEffect(() => {
    const el = profileRef.current;
    gsap.fromTo(
      el,
      { opacity: 0, y: 10 },
      {
        opacity: 1,
        y: 0,

        duration: 0.2,
        scrollTrigger: {
          trigger: el,
          start: "top ",
          end: "+=10",
          toggleActions: "play none none reverse",
          markers: false,
        },
      }
    );
  }, []);

  //  video section 

  const [show, setShow] = useState(false);
  const delay = 5;

  useEffect(
    () => {
      let timer1 = setTimeout(() => setShow(true), delay * 1000);

      return () => {
        clearTimeout(timer1);
      };
    },

    []
  );



  // scroll indicator 


  const [scrolls, setScroll] = useState(0);

  useEffect(() => {

    let progressBarHandler = () => {
      const totalScroll = document.documentElement.scrollTop;
      const windowHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      const scroll = `${totalScroll / windowHeight}`;
      const scrollPercentRounded = Math.round(scroll * 100);
      const degrees = scroll * 360;
      // console.log(degrees);
      setScroll(scroll);
      document.querySelector(
        ".bg"
      ).style.backgroundImage = `conic-gradient(#E1E1E1 ${degrees}deg, transparent ${degrees}deg)`;
      ;
    }

    window.addEventListener("scroll", progressBarHandler);

    return () => window.removeEventListener("scroll", progressBarHandler);
  });

  const title1 = useRef(null);
  const title2 = useRef(null);
  const title3 = useRef(null);
  const title4 = useRef(null);
  useEffect(() => {
    const tl = gsap.timeline({
      stagger: 2, scrollTrigger: {
        trigger: ".text1",
        start: "+=200",
        markers: false,
        toggleActions: "play none none none",
        // onEnter: () => console.log('text one animation startt'),

      }
    });
    tl.to(title1.current, { color: '#fff', ease: "power1.inOut", })
      // .to(title1.current, { color: '#A6A4B2' })
      .to(title2.current, { color: '#fff', ease: "power1.inOut", })
      // .to(title2.current, { color: '#A6A4B2'})
      .to(title3.current, { color: '#fff', ease: "power1.inOut", })
      // .to(title3.current, { color: '#A6A4B2' })
      .to(title4.current, { color: '#fff', ease: "power1.inOut", })
    // .to(title4.current, { color: '#A6A4B2' });
  }, []);

  const title5 = useRef(null);
  const title6 = useRef(null);
  const title7 = useRef(null);
  useEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".text2",
        start: "+=1000",
        markers: false,
        // onEnter: () => console.log('text two animation startt')
      }
    });
    tl.to(title5.current, { color: '#fff', ease: "power1.inOut", })
      // .to(title5.current, { color: '#8c899c', duration: 1 })
      .to(title6.current, { color: '#fff', ease: "power1.inOut", })
      // .to(title6.current, { color: '#8c899c', duration: 1 })
      .to(title7.current, { color: '#fff', ease: "power1.inOut", })
    // .to(title7.current, { color: '#8c899c', duration: 1 });
  }, [])

  const title8 = useRef(null);
  const title9 = useRef(null);
  const title10 = useRef(null);
  useEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".text3",
        start: "+=2100",
        markers: false,
        // onEnter: () => console.log('text 3 animation startt')
      }
    });
    tl.to(title8.current, { color: '#fff', ease: "power1.inOut", })
      // .to(title8.current, { color: '#8c899c', duration: 1 })
      .to(title9.current, { color: '#fff', ease: "power1.inOut", })
      // .to(title9.current, { color: '#8c899c', duration: 1 })
      .to(title10.current, { color: '#fff', ease: "power1.inOut", })
    // .to(title10.current, { color: '#8c899c', duration: 1 });
  }, [])

  const title11 = useRef(null);
  const title12 = useRef(null);
  const title13 = useRef(null);
  const title14 = useRef(null);
  useEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".text4",
        start: "+=3500",
        markers: false,
        // onEnter: () => console.log('text 4 animation startt'),
      }
    });
    tl.to(title11.current, { color: '#fff', ease: "power1.inOut", })
      // .to(title11.current, { color: '#8c899c', duration: 1 })
      .to(title12.current, { color: '#fff', ease: "power1.inOut", })
      // .to(title12.current, { color: '#8c899c', duration: 1 })
      .to(title13.current, { color: '#fff', ease: "power1.inOut", })
      // .to(title13.current, { color: '#8c899c', duration: 1 })
      .to(title14.current, { color: '#fff', ease: "power1.inOut", })
    // .to(title14.current, { color: '#8c899c', duration: 1 });
  }, [])

  const title15 = useRef(null);
  const title16 = useRef(null);
  const title17 = useRef(null);
  const title30 = useRef(null);
  const title31 = useRef(null);
  useEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".text5",
        start: "+=4700",
        end: "center center",
        markers: false,
        // onEnter: () => console.log('text 5 animation startt'),
      }
    });
    tl.to(title15.current, { color: '#fff', ease: "power1.inOut", })
      // .to(title15.current, { color: '#8c899c', duration: 1 })
      .to(title30.current, { color: '#fff', ease: "power1.inOut", })
      .to(title31.current, { color: '#fff', ease: "power1.inOut", })
      .to(title16.current, { color: '#fff', ease: "power1.inOut", })
      // .to(title16.current, { color: '#8c899c', duration: 1 })
      .to(title17.current, { color: '#fff', ease: "power1.inOut", })
    // .to(title17.current, { color: '#8c899c', duration: 1 });


  }, [])

  const title18 = useRef(null);
  const title19 = useRef(null);
  const title20 = useRef(null);
  useEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".text6",
        start: "+=5500",
        markers: false,
        // onEnter: () => console.log('text 6 animation startt'),
      }
    });
    tl.to(title18.current, { color: '#fff', ease: "power1.inOut", })
      // .to(title18.current, { color: '#8c899c', duration: 1 })
      .to(title19.current, { color: '#fff', ease: "power1.inOut", })
      // .to(title19.current, { color: '#8c899c', duration: 1 })
      .to(title20.current, { color: '#fff', ease: "power1.inOut", })
    // .to(title20.current, { color: '#8c899c', duration: 1 });
  }, [])

  const title21 = useRef(null);
  const title22 = useRef(null);
  const title23 = useRef(null);
  const title24 = useRef(null);
  const title25 = useRef(null);
  useEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".text7",
        start: "+=6600",
        markers: false,
        // onEnter: () => console.log('text 7 animation startt'),
      }
    });
    tl.to(title21.current, { color: '#fff', ease: "power1.inOut", })
      // .to(title21.current, { color: '#8c899c', duration: 1 })
      .to(title22.current, { color: '#fff', ease: "power1.inOut", })
      // .to(title22.current, { color: '#8c899c', duration: 1 })
      .to(title23.current, { color: '#fff', ease: "power1.inOut", })
      // .to(title23.current, { color: '#8c899c', duration: 1 })
      .to(title24.current, { color: '#fff', ease: "power1.inOut", })
      // .to(title24.current, { color: '#8c899c', duration: 1 })
      .to(title25.current, { color: '#fff', ease: "power1.inOut", })
    // .to(title25.current, { color: '#8c899c', duration: 1 });
  }, [])

  const title26 = useRef(null);
  const title27 = useRef(null);
  const title28 = useRef(null);
  useEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".project__title h2",
        start: "+=7800",
        markers: false,
        // onEnter: () => console.log('project__title h2 animation startt'),
      }
    });
    tl.to(title26.current, { color: '#fff', ease: "power1.inOut", })
      // .to(title26.current, { color: '#8c899c', duration: 1 })
      .to(title27.current, { color: '#fff', ease: "power1.inOut", })
      // .to(title27.current, { color: '#8c899c', duration: 1 })
      .to(title28.current, { color: '#fff', ease: "power1.inOut", })
    // .to(title28.current, { color: '#8c899c', duration: 1 });
  }, [])


  const footerRef = useRef(null)

  useEffect(() => {
    const el = footerRef.current

    gsap.fromTo(
      el, { y: 30, opacity: 0 }, { y: 0, duration: 0.7, opacity: 3, delay: 1.4 }

    )

  }, [])
  const scrollfixRef = useRef(null)

  useEffect(() => {
    const el = scrollfixRef.current

    gsap.fromTo(
      el, { y: 30, opacity: 0 }, { y: 0, duration: 0.7, opacity: 3, delay: 1.4 }

    )

  }, [])

  const mainText = useRef(null)

  useEffect(() => {
    const el = mainText.current
    gsap.fromTo(el, { y: 20, opacity: 0 },
      { y: 0, duration: 0.7, opacity: 3, delay: 1.4 })
  }, [])


  const [visible, setVisible] = useState(false)

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true)
    }
    else if (scrolled <= 300) {
      setVisible(false)
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  window.addEventListener('scroll', toggleVisible);


  useEffect(() => {
    let cursor = document.querySelector('.cursor');
    let cursorScale = document.querySelectorAll('.cursor-scale');
    let cursorBtnScale = document.querySelectorAll('.cursor-btn-scale');
    let mouseX = 0;
    let mouseY = 0;

    gsap.to({}, 0.016, {
      repeat: -1,
      onRepeat: function () {
        gsap.set(cursor, {
          css: {
            left: mouseX,
            top: mouseY,
          }
        })
      }
    });

    window.addEventListener('mousemove', (e) => {
      mouseX = e.clientX;
      mouseY = e.clientY;
    })

    cursorScale.forEach(link => {
      link.addEventListener('mousemove', () => {
        cursor.classList.add('grow');
        if (link.classList.contains('small')) {
          cursor.classList.remove('grow');
          cursor.classList.add('grow-small');
        }
      });

      link.addEventListener('mouseleave', () => {
        cursor.classList.remove('grow');
        cursor.classList.remove('grow-small');
      });
    })

    cursorBtnScale.forEach(link => {
      link.addEventListener('mousemove', () => {
        cursor.classList.add('grow-btn');
        if (link.classList.contains('small-btn')) {
          cursor.classList.remove('grow-btn');
          cursor.classList.add('grow-small-btn');
        }
      });

      link.addEventListener('mouseleave', () => {
        cursor.classList.remove('grow-btn');
        cursor.classList.remove('grow-small-btn');
      });
    })


  }, [])

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // Check if the user has scrolled, and update the state accordingly
      const scrolled = window.scrollY > 0;
      setIsScrolled(scrolled);
    };

    // Attach the event listener when the component mounts
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); // Empty dependency array ensures that the effect runs only once on mount



  return (
    <>

      <div className="cursor">

      </div>
      <section ref={designRef} className="design">
        <div className="design-pin">
          <div className="design-images">
            <canvas ref={canvasRef} loading="lazy" id="branding"></canvas>
          </div>
          <div className="text" >
            <h1 className="cursor-scale small" ref={mainText}>
              <span >Salman</span> <br /> <span  >Parcy</span>{" "}
            </h1>
          </div>
          <div className="text1">
            <h3 className="cursor-scale small">
              <span ref={title1}>It all started</span> <span ref={title2}>with a young boy</span> <span ref={title3}>who surfs anything that </span>
              <span ref={title4}> he comes across.</span>
            </h3>
          </div>
          <div className="text2">
            <h3 className="cursor-scale small">
              <span ref={title5}>I have always been</span>  <span ref={title6}>fascinated</span> <span ref={title7}>by the Internet and its advantages</span>
            </h3>
          </div>
          <div className="text3">
            <h3 className="cursor-scale small">
              <span ref={title8}>As a result, I took</span> <span ref={title9}>Web3</span> <span ref={title10}>as my mainstream.</span>
            </h3>
          </div>
          <div className="text4">
            <h3 className="cursor-scale small">
              <span ref={title11}>Over time as I dug further,</span> <span ref={title12}>I understood the potential</span> <span ref={title13}>of
                Blockchain, crypto, NFT, Tokenization, Metaverse,</span> <span ref={title14}> and overall the
                  Web 3 use-case.</span>
            </h3>
          </div>
          <div className="text5">
            <h3 className="cursor-scale small">
              <span ref={title15}>And now for the past 4 years,</span> <span ref={title30}>I'm in the motto of transforming </span>  <span ref={title31}>any
                existing business with the potential of</span>  <span ref={title16}>Blockchain and Web3</span> <span ref={title17}>that
                  contribute to a better future for all humanity.</span>
            </h3>
          </div>
          <div className="text6">
            <h2 className="cursor-scale small"><span ref={title18}>Now</span> <span ref={title19}>What I</span> <span ref={title20}>Do?</span></h2>
          </div>
          <div className="text7">
            <p className="cursor-scale small"><span ref={title21}>I Help Businesses To Understand The</span> <span ref={title22}>Opportunities Of Web 3.0.</span></p>
            <p className="cursor-scale small">
              {" "}
              <span ref={title23}>Basically, For My Clients I Like To Turn</span> <span ref={title24}>The Complexity Of A
                Chaotic World</span> <span ref={title25}>Into The Simplicity Of A Beautiful Solution.</span>
            </p>
          </div>
        </div>
      </section>
      <div id="overlay-section">
        <section className="project" ref={projectRef}>
          <div className="project__title" ref={projectTitle}>
            <h2 className="cursor-scale small">
              <span ref={title26}>Project That I <br /></span> <span ref={title27}>Collaborated</span> <span ref={title28}>On</span>
            </h2>
          </div>
        </section>

        <section className="work" ref={panel2}>
          <div className="work__row">
            <div className="black-overlay" ref={overlaySlide}></div>
            <div className="work__col-one" ref={workRef}>
              <div className="work__content">
                <h4 className="cursor-scale small">Working together to shape our world for difference.</h4>
                <p className="cursor-scale small">
                  I believe long-term, impactful partnerships are central to
                  achieving our mission. Therefore, we collaborate with others
                  to shape the world for difference — so every business can
                  thrive.
                </p>

                <div className="work__btn">
                  <a href={pdf} download>
                    <button className="cursor-btn-scale small-btn">
                      <span>Download Portfolio</span>
                      <img src={download} alt="" />
                    </button>
                  </a>
                </div>
              </div>
            </div>
            <div className="work__col-two">
              <div className="work__img" ref={imgRef}>
                <img src={globe} alt="" />
              </div>
            </div>
          </div>
        </section>




      </div>

      <div className="custom-scroll" ref={panel3}>
        <section className="profile" ref={scrollContainerRef}>
          <div className="custom-size">
            <div className="profile__title" ref={profileRef}>
              <h3 className="cursor-scale small">
                Let Me Roll Up My Sleeves To Work Shoulder-To-Shoulder On Your
                Business Challenges.{" "}
              </h3>
              <p className="cursor-scale">Just Give Me 30 Minutes!</p>
              <div className="profile__btn">
                <a href="https://calendly.com/salmanparcy-blockchainfirm/30min" target="_blank" className="cursor-btn-scale small-btn">Explore Opportunities</a>
              </div>
            </div>
          </div>

          <div id="scroll-container">
            <div id="container" ref={containerRef}>
              <div className="module">
                <img src={gallery1} alt="Blockchain Expert" />
              </div>
              <div className="module">
                <img src={gallery2} alt="Blockchain Expert" />
              </div>
              <div className="module">
                <img src={gallery3} alt="Blockchain Expert" />
              </div>
              <div className="module">
                <img src={gallery4} alt="Blockchain Expert" />
              </div>
              <div className="module">
                <img src={gallery5} alt="Blockchain Expert" />
              </div>
              {/* <div className="module">
                <img src={gallery6} alt="Blockchain Expert" />
              </div> */}
              <div className="module">
                <img src={gallery7} alt="Certified Blockchain Expert" />
              </div>
              <div className="module">
                <img src={gallery8} alt="Certified Blockchain Expert" />
              </div>
              <div className="module">
                <img src={gallery9} alt="Certified Blockchain Expert" />
              </div>
              <div className="module">
                <img src={gallery10} alt="Certified Blockchain Expert" />
              </div>
              <div className="module">
                <img src={gallery11} alt="Certified Blockchain Expert" />
              </div>
            </div>
          </div>
        </section>
      </div>



      <div className="footer" ref={footerRef}>
        <div className="custom-size">
          <div className="footer__flex">
            <div className="footer__one">
              <div className="footer__profile">
                <div className="footer__img">
                  {/* <img src={profile} alt="" /> */}
                  <div className="pc">
                    <div className="ol"></div>
                    <div className="bg">

                    </div>

                    <div className="flip-card">
                      <div className="flip-card-inner">
                        <div className="flip-card-front">
                          <img src={profile} alt="" />

                        </div>
                        <div className="flip-card-back">
                          <button className="top-btn" onClick={scrollToTop}
                            style={{ display: visible ? 'inline' : 'none' }}>
                            <img src={up} />
                          </button>
                        </div>
                      </div>
                    </div>



                  </div>
                </div>
                {/* <div className="footer__video" onClick={() => setShow(!show)}>
                  <button className={show ? "play-none" : "play-btn"}>
                    <span>Play Video</span>
                    <img src={video} alt="" />
                  </button>
                  {show && <div className="custom-video">
                    <video className="intro-video" poster={placeImage} autoPlay={true} loop={true} muted={true} plays-inline="" controls>
                      <source src={playVideo} type="video/mp4" />
                    </video>
                    <div className="custom-close" onClick={() => setShow(!show)}>
                      <img src={close} alt="" />
                    </div>
                  </div>}

                </div> */}

              </div>
            </div>
            <div className="footer__two">
              <ul>
                <li className="cursor-scale">
                  <a href="https://twitter.com/SalmanParcy_A" target="_blank">X</a>
                </li>
                <li>/</li>
                <li className="cursor-scale">
                  <a href="https://www.linkedin.com/in/salmanparcy/" target="_blank">ln</a>
                </li>
                <li>/</li>
                <li className="cursor-scale">
                  <a href="https://salmanparcy.medium.com/" target="_blank">me</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className={`scroll-fixed ${isScrolled ? 'display-none' : ''}`} ref={scrollfixRef}>
        <div>
          <div class="scroll-indicator indicator1"></div>
          <div class="scroll-indicator indicator2"></div>
        </div>

      </div>
    </>
  );
};

export default Home;
