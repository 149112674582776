import { Routes, Route } from 'react-router-dom' 
import Home from './Home/Home'
import Panel from './panel/panel'

export const Pagesrouter = () => {
  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  }
  return (
    <>
       <Routes>
        <Route >
          <Route path="" element={<Home></Home>}></Route>
          <Route path="/home" element={<Home></Home>}></Route>
          </Route>
      </Routes>
    </>
  )
}


