import { useRoutes } from "react-router-dom";
import { Layout } from "../../components/Layout/Layout";
export const Routes = () => {
    let routes = useRoutes([
        {
            path: "*", element: <Layout />,
        },
       
    ]);
    return routes;
}

