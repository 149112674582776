import { useEffect, useState, useRef } from "react";
import Menu from './Menu';
import "./header.scss";
import Logo from '../../assets/logo/salman_logo.svg';
import { gsap } from "gsap";
import { Link } from "react-router-dom";
export const Header = () => {

  // State of our Menu
  const [state, setState] = useState({
    initial: false,
    clicked: null,
    menuName: "Menu"
  });
  const [isMenu, setIsState] = useState(false);
  // State of our button
  const [disabled, setDisabled] = useState(false);

  //toggle menu
  const handleMenu = () => {
    setIsState(current => !current);
    disableMenu();
    if (state.initial === false) {
      setState({
        initial: null,
        clicked: true,
        menuName: "Close"
      });
    } else if (state.clicked === true) {
      setState({
        clicked: !state.clicked,
        menuName: "Menu"
      });
    } else if (state.clicked === false) {
      setState({
        clicked: !state.clicked,
        menuName: "Close"
      });
    }
  };

  // check if out button is disabled
  const disableMenu = () => {
    setDisabled(!disabled);
    setTimeout(() => {
      setDisabled(false);
    }, 1200);
  };

  const headerRef = useRef(null)

  useEffect(() => {

    const el = headerRef.current

    gsap.fromTo(
      el, { y: -20, opacity: 0 }, { y: 0, duration: 0.7, opacity: 3, delay: 1.4 }

    )

  }, [])

  function refreshPage() {
    window.location.reload();
  }




  return (
    <>
      <header >
        <div className="header" ref={headerRef}>
          <div className="custom-size">
            <div className="header__inner-header">
              <div className="header__width start">
                <div className="header__logo">
                  <Link to="" refresh="true">
                    <img className="cursor-scale" onClick={refreshPage} src={Logo} alt="logo" />
                  </Link>
                </div>
              </div>
              {/* <div className="header__width center cursor-scale">
                <button className="header__menu " disabled={disabled} onClick={handleMenu}>
                  <span className={isMenu ? 'lineTop spin' : 'lineTop'}>
                  </span>
                  <span className={!isMenu ? 'lineBottom' : 'lineBottom spin'}></span>
                </button>
              </div> */}
              <div className="header__width end">
                <div className="header__consult-btn cursor-btn-scale small-btn">
                  <a href="https://calendly.com/salmanparcy-blockchainfirm/30min" target="_blank" className="btn">Discover More</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Menu state={state} />
      </header>
    </>
  )
}

