import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { Link } from "react-router-dom";
import cup from '../../assets/others/cup.jpg';
import feed from '../../assets/others/feed.jpg';
import blog from '../../assets/others/blog.jpg'
import home from '../../assets/others/home.jpg'

import {
  menuShow,
  menuHide,
  staggerLinks,
  textIntro,
  // hoverLink,
  // hoverExit
} from "./Animate";


const Menu = ({ state }) => {

  function changeImg(e) {
    gsap.set("#img1", { autoAlpha: 1 });
  }
  function removeImg(e) {
    gsap.set("#img1", { autoAlpha: 0 });
  }
  function changeImg1(e) {
    gsap.set("#img2", { autoAlpha: 1 });
  }
  function removeImg1(e) {
    gsap.set("#img2", { autoAlpha: 0 });
  }
  function changeImg2(e) {
    gsap.set("#img3", { autoAlpha: 1 });
  }
  function removeImg2(e) {
    gsap.set("#img3", { autoAlpha: 0 });
  }
  function changeImg3(e) {
    gsap.set("#img4", { autoAlpha: 1 });
  }
  function removeImg3(e) {
    gsap.set("#img4", { autoAlpha: 0 });
  }
  //create refs for our DOM elements
  let menuWrapper = useRef(null);
  let show1 = useRef(null);
  let show2 = useRef(null);
  let info = useRef(null);
  let line1 = useRef(null);
  let line2 = useRef(null);
  let line3 = useRef(null);
  let line4 = useRef(null);

  useEffect(() => {
    // If the menu is open and we click the menu button to close it.
    if (state.clicked === false) {
      // If menu is closed and we want to open it.

      menuHide(show2, show1);
      // Set menu to display none
      gsap.to(menuWrapper, { duration: 1, css: { display: "none" } });
    } else if (
      state.clicked === true ||
      (state.clicked === true && state.initial === null)
    ) {
      // Set menu to display block
      gsap.to(menuWrapper, { duration: 0, css: { display: "block" } });
      //Allow menu to have height of 100%
      gsap.to([show1, show2], {
        duration: 0,
        opacity: 1,
        height: "100%"
      });
      menuShow(show1, show2);
      textIntro(info);
      staggerLinks(line1, line2, line3, line4);
    }
  }, [state]);

  return (
    <div ref={(el) => (menuWrapper = el)} className="hamburger-menu">
      <div
        ref={(el) => (show1 = el)}
        className="menu-secondary-background-color"
      ></div>
      <div ref={(el) => (show2 = el)} className="menu-layer">
        <div className="container">
          <div className="wrapper">
            <div className="menu-links">
              <nav>
                <ul>
                  <li className="list_link cursor-scale small">
                    <Link
                      onMouseEnter={(e) => changeImg3(e)}
                      onMouseOut={(e) => removeImg3(e)}
                      ref={(el) => (line1 = el)}
                      to="/home">
                      Home
                    </Link>
                  </li>
                  <li className="list_link cursor-scale small">
                    <Link
                      onMouseEnter={(e) => changeImg1(e)}
                      onMouseOut={(e) => removeImg1(e)}
                      ref={(el) => (line2 = el)}
                      to="/about-us"
                    >
                      Feed
                    </Link>
                  </li>
                  <li className="list_link cursor-scale small">
                    <Link
                      onMouseEnter={(e) => changeImg(e)}
                      onMouseOut={(e) => removeImg(e)}
                      ref={(el) => (line3 = el)}
                      to="/awards"
                    >
                      Awards & Recognition
                    </Link>
                  </li>
                  <li className="list_link cursor-scale small">
                    <Link
                      onMouseEnter={(e) => changeImg2(e)}
                      onMouseOut={(e) => removeImg2(e)}
                      ref={(el) => (line4 = el)}
                      to="/blog"
                    >
                      Blog
                    </Link>
                  </li>
                  <li className="responsive-btn">
                    <div className="header__consult-btn cursor-scale small">
                      <button className="btn">Consultation</button>
                    </div>
                  </li>
                  <li className="contact list_link">
                    contact me:&nbsp;
                    <span className="contact__list">
                      <div className="cursor-scale">tw</div>
                      <div className="cursor-scale">ln</div>
                      <div className="cursor-scale">me</div>
                    </span>
                  </li>
                  
                </ul>
              </nav>
              <div className="info" id="book">
                <div id="img1" className="page">
                  <img src={cup} alt="des" className="img3" />
                </div>
                <div id="img2" className="page">
                  <img src={feed} alt="des" className="img3" />
                </div>
                <div id="img3" className="page">
                  <img src={blog} alt="des" className="img3" />
                </div>
                <div id="img4" className="page">
                  <img src={home} alt="des" className="img3" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
